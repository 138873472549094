<template>
  <div class="play-game-container">
    <div class="play-game-content">
      <h1>Get7</h1>
      <div v-if="!gameOver" class="play-game">
        <!-- If user has already played today -->
        <div v-if="alreadyPlayedToday" class="already-played-message">
          <h2>You've already played today!</h2>
          <p>Check back tomorrow for a new game.</p>
          <button @click="navigateToLeaderboard" class="btn btn-primary">View Leaderboard</button>
        </div>

        <!-- If user is logged in and has player role, show the game -->
        <div v-else-if="user && isPlayer && !alreadyPlayedToday">
          {{ console.log('Game:', game) }}
          {{ console.log('Shuffled Answers:', shuffledAnswers) }}
          <div class="header">
            <div class="menu-container">
              <button @click="toggleMenu" class="menu-button">
                <i class="fas fa-bars"></i>
              </button>
              <div v-if="showMenu" class="dropdown-menu">
                <button @click="goToLeaderboard" class="menu-item">Weekly Leaderboard</button>
                <button v-if="isAdmin" @click="goToAdmin" class="menu-item">Admin</button>
                <button @click="logout" class="menu-item">Logout</button>
              </div>
            </div>
          </div>
          <h2>{{ game?.questionText }}</h2>
          <p class="question-short-description">{{ game?.questionShortDescription }}</p>
          <h3>Your Current Score: {{ currentScore }}</h3>
          <button 
            @mousedown="startBankHold"
            @mouseup="cancelBankHold"
            @mouseleave="cancelBankHold"
            @touchstart="startBankHold"
            @touchend="cancelBankHold"
            class="btn btn-success bank-btn" 
            :disabled="currentScore === 0 || gameOver"
          >
            <div class="fill-overlay" :style="{ width: bankHoldProgress + '%' }"></div>
            <span>Bank Points</span>
          </button>

          <p class="instruction">Tap and hold an answer to select it</p>

          <!-- Add bomb icons -->
          <div class="bomb-icons">
            <i v-for="n in 3" :key="n" :class="[n <= bombCount ? 'fas fa-bomb exploded' : 'fas fa-bomb']"></i>
          </div>

          <div class="answers">
            <div v-for="(answer, index) in shuffledAnswers" :key="index" class="answer-wrapper">
              <button 
                :disabled="answer.clicked" 
                @mousedown="startHold(index)"
                @mouseup="cancelHold(index)"
                @mouseleave="cancelHold(index)"
                @touchstart="onTouchStart($event); startHold(index)"
                @touchmove="onTouchMove($event)"
                @touchend="cancelHold(index)"
                :class="['answer-btn', answer.clicked ? (answer.correct ? 'correct' : 'bomb') : '', answer.animationClass]"
              >
                <div v-if="!answer.clicked" class="fill-overlay" :style="{ width: answer.holdProgress + '%' }"></div>
                <span class="answer-text">{{ answer.text }}</span>
                <span v-if="answer.clicked" class="answer-result">
                  <template v-if="answer.correct">{{ answer.display }}</template>
                  <i v-else class="fas fa-bomb"></i>
                </span>
              </button>
            </div>
          </div>        
        </div>

        <!-- First Game Modal -->
        <div v-if="showFirstGameModal" class="modal-overlay">
          <div class="modal-content">
            <h2>Welcome to Get7!</h2>
            <p>Here's how to play:</p>
            <ul>
              <li>Find 7 correct answers in the category.</li>
              <li>Avoid 3 incorrect answers (bombs).</li>
              <li>Correct answers start at 100 points each.</li>
              <li>Each bomb halves the value of all answers.</li>
              <li>Bank your points anytime to secure your score.</li>
              <li>Play daily to climb the weekly leaderboard!</li>
            </ul>
            <button @click="closeFirstGameModal" class="btn btn-primary">Let's Play!</button>
          </div>
        </div>
      </div>

      <GameOverView v-else :currentGameScore="currentScore" />
    </div>
  </div>
</template>

<script>
import GameOverView from './GameOverView.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { getDoc, doc, collection, query, where, getDocs, setDoc, updateDoc, serverTimestamp, orderBy, limit } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { ref, onMounted, watch } from 'vue';

export default {
  components: {
    GameOverView
  },
  data() {
    return {
      user: null,
      isPlayer: false,
      game: null,
      shuffledAnswers: [],
      currentScore: 0,
      bombCount: 0,
      gameOver: false,
      currentQuestionValue: 0,
      holdDuration: 1000,  // Hold duration in milliseconds
      holdInterval: null,
      bankHoldProgress: 0,
      bankHoldInterval: null,
      bankHoldDuration: 1000, // 
      isScrolling: false,
      touchStartY: 0,
      scrollThreshold: 5,  // Minimum distance in pixels to be considered a scroll
      alreadyPlayedToday: false,
    };
  },
  async created() {
    onAuthStateChanged(auth, async (user) => {
      this.user = user;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log('User roles:', userData.roles);
          this.isPlayer = userData.roles && userData.roles.includes('player');
          if (this.isPlayer) {
            await this.fetchTodayGame();
          }
        } else {
          console.error('User document does not exist');
          // Create user document if it doesn't exist
          await setDoc(doc(db, 'users', user.uid), {
            email: user.email,
            roles: ['player'],
            username: user.displayName || 'Player',
            createdAt: new Date().toISOString()
          });
          this.isPlayer = true;
          await this.fetchTodayGame();
        }
      } else {
        this.isPlayer = false;
        this.$router.push('/login');
      }
    });
  },
  setup() {
    const router = useRouter();
    const showFirstGameModal = ref(false);
    const showMenu = ref(false);
    const isAdmin = ref(false);

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const goToLeaderboard = () => {
      router.push('/weekly-leaderboard');
      showMenu.value = false;
    };

    const goToAdmin = () => {
      router.push('/admin');
      showMenu.value = false;
    };

    const logout = async () => {
      try {
        await signOut(auth);
        router.push('/');
      } catch (error) {
        console.error('Error logging out:', error);
      }
      showMenu.value = false;
    };

    const checkFirstGame = async () => {
      const user = auth.currentUser;
      if (user) {
        const scoresRef = collection(db, `users/${user.uid}/scores`);
        const scoreSnapshot = await getDocs(scoresRef);
        if (scoreSnapshot.empty) {
          showFirstGameModal.value = true;
        }
      }
    };

    const closeFirstGameModal = () => {
      showFirstGameModal.value = false;
    };

    const checkAdminStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          isAdmin.value = userData.roles && userData.roles.includes('admin');
        }
      }
    };

    onMounted(() => {
      checkFirstGame();
      checkAdminStatus();
    });

    // Watch for changes in user authentication state
    watch(() => auth.currentUser, (newUser) => {
      if (!newUser) {
        // Redirect to login page if user is not logged in
        router.push('/login');
      }
    }, { immediate: true });

    return {
      showFirstGameModal,
      closeFirstGameModal,
      showMenu,
      toggleMenu,
      goToLeaderboard,
      goToAdmin,
      logout,
      isAdmin,
    };
  },
  methods: {
    // Track the starting Y position when the user touches the screen
    onTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
      this.isScrolling = false; // Reset scroll state
    },

    // Check if the user is scrolling by comparing touch start and move positions
    onTouchMove(event) {
      const touchCurrentY = event.touches[0].clientY;
      const distanceMoved = Math.abs(touchCurrentY - this.touchStartY);

      if (distanceMoved > this.scrollThreshold) {
        this.isScrolling = true; // Consider this a scroll if the user has moved more than the threshold
      }
    },

    startHold(index) {
      if (this.shuffledAnswers[index].clicked || this.isScrolling) return; // Skip if scrolling

      // Initialize hold progress
      this.shuffledAnswers[index].holdProgress = 0;

      clearInterval(this.holdInterval);

      this.holdInterval = setInterval(() => {
        this.shuffledAnswers[index].holdProgress += 100 / (this.holdDuration / 100);

        if (this.shuffledAnswers[index].holdProgress >= 100) {
          clearInterval(this.holdInterval);
          this.handleAnswerClick(this.shuffledAnswers[index]);
        }
      }, 100);
    },

    cancelHold(index) {
      clearInterval(this.holdInterval);

      if (this.shuffledAnswers[index].holdProgress < 100) {
        this.shuffledAnswers[index].holdProgress = 0;
      }
    },

    handleAnswerClick(answer) {
      if (!answer.clicked) {
        answer.clicked = true;
        answer.holdProgress = 0;

        if (answer.correct) {
          answer.displayValue = this.currentQuestionValue;
          answer.display = `${answer.displayValue} points`;
          answer.animationClass = 'correct-animation';
          this.currentScore += Number(answer.displayValue);
        } else {
          answer.display = '';
          this.bombCount++;
          this.reducePoints();
          answer.animationClass = 'bomb-animation';
        }

        this.updateCurrentScore();

        setTimeout(() => {
          answer.animationClass = '';
        }, 500);

        if (this.bombCount >= 3 || this.allBombsRemaining()) {
          this.endGame();
        }
      }
    },

    reducePoints() {
      this.currentQuestionValue = Math.floor(this.currentQuestionValue / 2);

      this.shuffledAnswers.forEach(answer => {
        if (answer.correct && answer.clicked) {
          answer.displayValue = this.currentQuestionValue;
          answer.display = `${answer.displayValue} points`;
        }
      });

      this.updateCurrentScore();
    },

    updateCurrentScore() {
      this.currentScore = this.shuffledAnswers.reduce((total, answer) => {
        if (answer.correct && answer.clicked) {
          return Number(total) + Number(answer.displayValue);
        }
        return total;
      }, 0);
    },

    initializeGame() {
      if (this.game) {
        this.shuffledAnswers = [
          ...this.game.correctAnswers.map(a => ({ text: a, correct: true, clicked: false, display: '', displayValue: 0, holdProgress: 0 })), 
          ...this.game.incorrectAnswers.map(a => ({ text: a, correct: false, clicked: false, display: '', displayValue: 0, holdProgress: 0 }))
        ];
        this.shuffledAnswers = this.shuffledAnswers.sort(() => Math.random() - 0.5);
        console.log('Shuffled Answers:', this.shuffledAnswers);
          
        this.currentScore = 0;
        this.currentQuestionValue = this.game.basePoints;
        this.bombCount = 0;
        this.gameOver = false;
      }
    },

    async fetchTodayGame() {
      console.log('Fetching today\'s game');
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      console.log('Today\'s date:', today);
      console.log('Tomorrow\'s date:', tomorrow);

      const gamesRef = collection(db, 'games');
      const q = query(gamesRef, 
        where('liveDate', '>=', today),
        where('liveDate', '<', tomorrow),
        orderBy('liveDate', 'desc'),
        limit(1)
      );
      
      try {
        const querySnapshot = await getDocs(q);
        console.log('Query snapshot size:', querySnapshot.size);
        
        if (!querySnapshot.empty) {
          const gameDoc = querySnapshot.docs[0];
          console.log('Game document:', gameDoc.data());
          this.game = { id: gameDoc.id, ...gameDoc.data() };
          this.setupGame();
        } else {
          console.log('No game found for today');
          this.game = null;
        }
      } catch (error) {
        console.error('Error fetching today\'s game:', error);
      }
    },

    startBankHold() {
      if (this.currentScore === 0 || this.gameOver) return;

      this.bankHoldProgress = 0;
      clearInterval(this.bankHoldInterval);

      this.bankHoldInterval = setInterval(() => {
        this.bankHoldProgress += 100 / (this.bankHoldDuration / 100);

        if (this.bankHoldProgress >= 100) {
          clearInterval(this.bankHoldInterval);
          this.bankPoints();
        }
      }, 100);
    },

    cancelBankHold() {
      clearInterval(this.bankHoldInterval);
      this.bankHoldProgress = 0;
    },

    async bankPoints() {
      if (this.user && this.currentScore > 0) {
        try {
          const today = new Date().toISOString().split('T')[0];

          const scoreRef = doc(db, `users/${this.user.uid}/scores/${today}`);
          const scoreDoc = await getDoc(scoreRef);

          if (!scoreDoc.exists()) {
            await setDoc(scoreRef, { bankedScore: this.currentScore, date: today });
            await this.updateWeeklyScore(this.currentScore);
            await this.endGame();
          } else {
            console.log('User has already played today');
            this.$router.push('/weekly-leaderboard');
          }
        } catch (error) {
          console.error("Error banking points: ", error);
          alert("Failed to bank points: " + error.message);
        }
      }
    },

    async updateWeeklyScore(score) {
      const user = auth.currentUser;
      if (user) {
        const weeklyScoreRef = doc(db, 'weeklyScores', user.uid);
        
        await setDoc(weeklyScoreRef, {
          userId: user.uid,
          score: score,
          lastUpdated: serverTimestamp()
        }, { merge: true });
      }
    },

    async endGame() {
      this.gameOver = true;
      await this.updateWeeklyScore(this.currentScore);
      await this.saveScore();
    },

    async saveScore() {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          lastGameScore: this.currentScore
        });
      }
    },

    allBombsRemaining() {
      return this.shuffledAnswers.every(answer => answer.clicked || !answer.correct);
    },

    navigateToLeaderboard() {
      this.$router.push('/weekly-leaderboard');
    },

    setupGame() {
      this.initializeGame();
      // Any other setup logic you need
    }
  },
  mounted() {
    this.shuffledAnswers.forEach(answer => {
      answer.holdProgress = 0;
    });
  },
  watch: {
    game(newGame) {
      if (newGame) {
        this.setupGame();
      }
    }
  }
};
</script>

<style scoped>
.play-game-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
  box-sizing: border-box;
}

.play-game-content {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  padding: 40px;
  width: 100%;
  max-width: 600px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.btn {
  padding: 10px 20px;
  margin: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bank-btn {
  position: relative;
  background-color: #28a745;
  color: white;
  font-weight: bold;
  padding: 11.25px 22.5px; /* Reduced by 25% from 15px 30px */
  margin-top: 15px; /* Reduced by 25% from 20px */
  overflow: hidden;
  font-size: 13.5px; /* Reduced by 25% from 18px, adjust if needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bank-btn:hover:not(:disabled) {
  background-color: #218838;
}

.bank-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.bank-btn .fill-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: width 0.1s linear;
  pointer-events: none;
}

.bank-btn span {
  position: relative;
  z-index: 1;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.answer-wrapper {
  width: calc(50% - 10px); /* 50% width minus half of the gap */
  margin-bottom: 20px;
}

.answer-btn {
  position: relative;
  width: 100%;
  height: 80px; /* Set a fixed height */
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px #0056b3;
  transition: transform 0.1s ease, box-shadow 0.1s ease, background-color 0.3s ease;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.answer-btn:active {
  transform: translateY(4px);
  box-shadow: 0 0 #003a75;
}

.fill-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  transition: width 0.1s linear;
  pointer-events: none;
}

.answer-btn.correct {
  background-color: #28a745;
  box-shadow: 0 4px #1e7e34;
}

.answer-btn.bomb {
  background-color: #dc3545;
  box-shadow: 0 4px #bd2130;
}

.answer-btn[disabled] {
  cursor: default;
  transform: none;
}

.answer-text {
  transition: transform 0.3s ease;
}

.answer-btn.correct .answer-text,
.answer-btn.bomb .answer-text {
  transform: translateY(-10px);
  font-size: 0.9em;
}

.answer-result {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  font-size: 0.9em;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.answer-btn.correct .answer-result,
.answer-btn.bomb .answer-result {
  opacity: 1;
}

.bomb-icons {
  font-size: 24px;
  margin-bottom: 20px;
}

.bomb-icons i {
  margin: 0 5px;
  transition: all 0.3s ease;
}

.bomb-icons .exploded {
  color: #dc3545;
  transform: scale(1.2);
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.bomb-icons .exploded {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

.correct-animation {
  animation: pulse 0.5s;
}

.bomb-animation {
  animation: shake 0.5s;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.instruction {
  margin-top: 20px;
  font-style: italic;
  color: #6c757d;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content ul {
  text-align: left;
  padding-left: 1.5rem;
}

.modal-content li {
  margin-bottom: 0.5rem;
}

.modal-content .btn {
  margin-top: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.menu-container {
  position: relative;
}

.menu-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
}

.menu-item {
  display: block;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 16px;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.already-played-message {
  text-align: center;
  margin-top: 2rem;
}

.already-played-message h2 {
  color: #333;
  margin-bottom: 1rem;
}

.already-played-message p {
  color: #666;
  margin-bottom: 2rem;
}

.already-played-message .btn {
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
}
</style>
