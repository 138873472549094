<template>
  <div class="weekly-leaderboard-container">
    <div class="leaderboard-box">
      <div class="navigation-buttons">
        <button @click="goToHome" class="nav-button">Home</button>
        <button @click="goToMyStats" class="nav-button">My Stats</button>
      </div>

      <h2>Weekly Leaderboard</h2>
      
      <div v-if="userRank" class="user-rank">
        <h3>My Rank: {{ userRank }}</h3>
        <p>Score: {{ userScore }}</p>
      </div>

      <div class="leaderboard-list">
        <div v-for="(player, index) in topPlayers" :key="player.id" class="player-row">
          <div class="rank">{{ index + 1 }}</div>
          <div class="player-info">
            <span class="username">{{ player.username }}</span>
          </div>
          <div class="score">{{ player.score }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '../firebase';
import { collection, query, orderBy, limit, getDocs, getDoc, doc, where } from 'firebase/firestore';

export default {
  name: 'WeeklyLeaderboardView',
  setup() {
    const router = useRouter();
    const topPlayers = ref([]);
    const userRank = ref(null);
    const userScore = ref(0);

    const getWeeklyLeaderboard = async () => {
      try {
        // Get the start of the current week (Sunday)
        const now = new Date();
        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
        startOfWeek.setHours(0, 0, 0, 0);

        console.log('Start of week:', startOfWeek);

        const leaderboardQuery = query(
          collection(db, 'weeklyScores'),
          where('lastUpdated', '>=', startOfWeek),
          orderBy('lastUpdated', 'desc'),
          orderBy('score', 'desc'),
          limit(100)
        );

        const querySnapshot = await getDocs(leaderboardQuery);
        console.log('Number of documents:', querySnapshot.size);

        const leaderboardData = [];
        const seenUsers = new Set();

        for (const docSnapshot of querySnapshot.docs) {
          const data = docSnapshot.data();
          console.log('Score document:', data);

          if (data.lastUpdated) {
            console.log('lastUpdated:', data.lastUpdated.toDate());
          } else {
            console.log('lastUpdated field is missing');
          }

          // Only add the user's highest score for the week
          if (!seenUsers.has(data.userId)) {
            seenUsers.add(data.userId);

            const userDocRef = doc(db, 'users', data.userId);
            const userDocSnapshot = await getDoc(userDocRef);
            const userData = userDocSnapshot.data();
            console.log('User document:', userData);

            leaderboardData.push({
              id: data.userId,
              username: userData?.username || 'Unknown User',
              score: data.score,
            });
          }
        }

        console.log('Leaderboard data:', leaderboardData);

        // Sort the leaderboard data by score in descending order
        leaderboardData.sort((a, b) => b.score - a.score);

        topPlayers.value = leaderboardData;

        // Get user's rank
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userIndex = leaderboardData.findIndex(player => player.id === currentUser.uid);
          if (userIndex !== -1) {
            userRank.value = userIndex + 1;
            userScore.value = leaderboardData[userIndex].score;
          }
        }
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    const goToHome = () => {
      router.push('/play');
    };

    const goToMyStats = () => {
      router.push('/my-stats');
    };

    onMounted(() => {
      getWeeklyLeaderboard();
    });

    return {
      topPlayers,
      userRank,
      userScore,
      goToHome,
      goToMyStats,
    };
  },
};
</script>

<style scoped>
.weekly-leaderboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
  box-sizing: border-box;
}

.leaderboard-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  padding: 40px;
  width: 100%;
  max-width: 600px;
}

h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.user-rank {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.user-rank h3 {
  font-size: 20px;
  color: #764ba2;
  margin-bottom: 5px;
}

.leaderboard-list {
  max-height: 400px;
  overflow-y: auto;
}

.player-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.rank {
  font-size: 18px;
  font-weight: bold;
  color: #764ba2;
  width: 40px;
  text-align: center;
}

.player-info {
  flex-grow: 1;
  margin-left: 10px;
}

.username {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.score {
  font-size: 18px;
  font-weight: bold;
  color: #764ba2;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nav-button {
  background-color: #764ba2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #5a3a80;
}
</style>