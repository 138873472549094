<template>
    <div class="games-list">
      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Live Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="game in sortedGames" :key="game.id">
            <td>{{ game.questionText }}</td>
            <td>{{ formatDate(game.liveDate) }}</td>
            <td>
              <button @click="editGame(game)" class="btn btn-primary">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import { db } from '../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { format } from 'date-fns';

export default {
  data() {
    return {
      games: []
    };
  },
  computed: {
    sortedGames() {
      return [...this.games].sort((a, b) => {
        const dateA = a.liveDate instanceof Date ? a.liveDate : new Date(a.liveDate.seconds * 1000);
        const dateB = b.liveDate instanceof Date ? b.liveDate : new Date(b.liveDate.seconds * 1000);
        return dateB - dateA;
      });
    }
  },
  mounted() {
    this.fetchGames();
  },
  methods: {
    async fetchGames() {
      const gamesCollection = collection(db, 'games');
      const q = query(gamesCollection, orderBy('liveDate', 'desc'));
      const gamesSnapshot = await getDocs(q);
      this.games = gamesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    },
    editGame(game) {
      this.$emit('edit-game', game);
    },
    formatDate(date) {
      if (date instanceof Date) {
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      } else if (date && date.seconds) {
        return format(new Date(date.seconds * 1000), 'yyyy-MM-dd HH:mm:ss');
      }
      return 'Invalid Date';
    }
  }
};
</script>

<style scoped>
.games-list {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
