<template>
    <div class="my-stats">
      <h2>My Stats</h2>
      <p>This feature is coming soon!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MyStatsView'
  }
  </script>