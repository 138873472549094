import { createRouter, createWebHistory } from 'vue-router';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import LoginRegisterView from '../views/LoginRegisterView.vue';
import PlayGameView from '../views/PlayGameView.vue';
import AdminView from '../views/AdminView.vue';
import GameOverView from '../views/GameOverView.vue';
import WeeklyLeaderboardView from '../views/WeeklyLeaderboardView.vue';
import MyStatsView from '../views/MyStatsView.vue';
import CompleteProfileView from '../views/CompleteProfileView.vue';

const routes = [
  {
    path: '/',
    name: 'LoginRegister',
    component: LoginRegisterView
  },
  {
    path: '/play',
    name: 'PlayGame',
    component: PlayGameView,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/game-over',
    name: 'GameOver',
    component: GameOverView,
    props: true
  },
  {
    path: '/weekly-leaderboard',
    name: 'WeeklyLeaderboard',
    component: WeeklyLeaderboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-stats',
    name: 'MyStats',
    component: MyStatsView
  },
  {
    path: '/complete-profile',
    name: 'CompleteProfile',
    component: CompleteProfileView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginRegisterView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  auth.onAuthStateChanged(async (user) => {
    if (requiresAuth && !user) {
      next('/');
    } else if (requiresAdmin) {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.roles && userData.roles.includes('admin')) {
            next();
          } else {
            next('/play');
          }
        } else {
          next('/play');
        }
      } else {
        next('/');
      }
    } else {
      next();
    }
  });
});

export default router;