<template>
  <div class="game-over-container">
    <div class="game-over-box">
      <h2>Game Over!</h2>
      <div class="score-info">
        <p>Your score for this game: <span class="score">{{ currentGameScore }}</span></p>
        <p>Your total lifetime score: <span class="score">{{ lifetimeScore }}</span></p>
      </div>
      <div class="buttons">
        <button @click="goToWeeklyLeaderboard" class="btn btn-primary">Weekly Leaderboard</button>
        <button @click="goToMyStats" class="btn btn-secondary">My Stats</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export default {
  name: 'GameOverView',
  props: {
    currentGameScore: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const lifetimeScore = ref(0);

    const fetchLifetimeScore = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          lifetimeScore.value = userDoc.data().lifetimeScore || 0;
        }
      }
    };

    const updateLifetimeScore = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          lifetimeScore: lifetimeScore.value + props.currentGameScore
        });
      }
    };

    const goToWeeklyLeaderboard = () => {
      router.push('/weekly-leaderboard');
    };

    const goToMyStats = () => {
      router.push('/my-stats');
    };

    onMounted(async () => {
      await fetchLifetimeScore();
      await updateLifetimeScore();
    });

    return {
      lifetimeScore,
      goToWeeklyLeaderboard,
      goToMyStats
    };
  }
};
</script>

<style scoped>
.game-over-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
}

.game-over-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  padding: 40px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.score-info {
  margin-bottom: 30px;
}

p {
  font-size: 18px;
  color: #666;
  margin: 10px 0;
}

.score {
  font-weight: bold;
  color: #764ba2;
  font-size: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.btn {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.btn-primary {
  background-color: #764ba2;
  color: white;
}

.btn-primary:hover {
  background-color: #5e3d82;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}
</style>