<template>
    <div class="complete-profile-container">
      <div class="profile-box">
        <h2>Complete Your Profile</h2>
        <form @submit.prevent="completeProfile">
          <div class="input-group">
            <input v-model="username" type="text" required>
            <label>Username for Leaderboard</label>
          </div>
          <button type="submit" class="btn">Complete Profile</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { auth, db } from '../firebase';
  import { doc, setDoc } from 'firebase/firestore';
  
  export default {
    setup() {
      const router = useRouter();
      const username = ref('');
  
      const completeProfile = async () => {
        try {
          const user = auth.currentUser;
          if (user) {
            await setDoc(doc(db, 'users', user.uid), {
              username: username.value,
              email: user.email,
            }, { merge: true });
            router.push('/play');
          }
        } catch (error) {
          console.error("Error completing profile:", error);
          alert(error.message);
        }
      };
  
      return {
        username,
        completeProfile,
      };
    }
  };
  </script>
  
  <style scoped>
  .complete-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
    padding: 20px;
  }
  
  .profile-box {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 40px;
    width: 100%;
    max-width: 400px;
  }
  
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #764ba2;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-btn:hover {
    background-color: #5a3a80;
  }
  </style>